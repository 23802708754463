<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="2" class="my-6" v-if="accountService">
                        <v-app-bar :color="primaryColor" dark flat dense>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn icon @click="$router.go(-1)" v-bind="attrs" v-on="on">
                                        <font-awesome-icon :icon="['fas', 'chevron-left']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                Back
                            </v-tooltip>
                            <v-app-bar-title>Manage service</v-app-bar-title>
                            <!-- <v-spacer/>
                            <v-menu offset-y left open-on-click open-on-hover close-delay="100">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                <v-list class="ma-0 pa-0">
                                    <v-list-item-group>
                                    <v-list-item :to="{ name: 'account-dashboard', params: { brandprofile: this.$route.params.brandprofile, accountId: this.$route.params.accountId } }">
                                        <v-list-item-icon>
                                            <font-awesome-icon :icon="['fas', 'cog']" :color="primaryColor" fixed-width/>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Overview</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-menu> -->
                        </v-app-bar>
                        <v-simple-table dense>
                            <template #default>
                                <tbody>
                                    <tr>
                                        <th style="min-width: 100px;">Name</th>
                                        <td style="width: 100%;">
                                            <v-row no-gutters align="center">
                                                {{ accountService.name }}
                                                <!-- <v-btn :color="primaryColor" class="no-print" @click="dialogEditAccountName = true" icon>
                                                    <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                                                </v-btn> -->
                                                <!-- <EditableText :value="product.name" @input="saveProductName" dense/> -->
                                                <!-- <ProductLink :organization="organization" :product="product" by="id" target="_blank">Link with ID</ProductLink> -->
                                            </v-row>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style="min-width: 100px;">Type</th>
                                        <td style="width: 100%;">
                                            {{ productTypeDisplay }}
                                            <!-- <v-row no-gutters align="center">
                                                <EditableText :value="product.name" @input="saveProductName" dense/>
                                                <ProductLink :organization="organization" :product="product" by="id" target="_blank">Link with ID</ProductLink>
                                            </v-row> -->
                                        </td>
                                    </tr>
                                    <!-- TODO: show the product info (including the website link and the sign-in link) -->
                                    <!-- TODO: show the price info -->
                                </tbody>
                            </template>
                        </v-simple-table>
                        <!-- <v-card-text> -->
                            <!-- <p class="text-overline mb-0 mt-2">Account Name</p>
                            <p class="mb-0 pb-0">
                                {{ accountBillingSummary.account_name }}
                            </p>

                            <p class="text-overline mb-0 mt-2">Account Type</p>
                            <p class="mb-0 pb-0">
                                {{ accountBillingSummary.account_type }}
                            </p> -->

                            <!-- <p class="text-overline mb-0 mt-8">Other</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-delete', params: { accountId: this.$route.params.accountId } }">How to delete this account</router-link>
                            </p>
 -->
                            <!-- <p class="text-overline mb-0 mt-5">Profile</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="profileRoute">My profile</router-link>
                            </p> -->
                            <!-- <p class="text-overline mb-0 mt-10">Organizations</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'user-organization-list' }">Manage organizations</router-link>
                            </p>
                            <template v-if="isPermitServiceAdmin">
                            <p class="text-overline mb-0 mt-10">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"/></p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'service-admin' }">Manage Unicorn Springs</router-link>
                            </p>
                            </template> -->
                        <!-- </v-card-text> -->
                    </v-card>
                    <!-- <v-dialog v-model="dialogEditAccountName" max-width="600">
                        <v-card elevation="4" class="pa-5">
                            <v-card-text class="text-h6 pa-0 pb-3">Change account name</v-card-text>
                            <v-row justify="center" class="pt-3">
                                <v-col cols="12">
                                    <div class="font-weight-light text-body-1">
                                        <p>We will use this name to refer to your account on our website and when we communicate by email, telephone, or letters.</p>
                                        <p>You can use your company or department name, project name, or any other name that makes sense to you.</p>
                                        <p>The account name is not displayed to your contacts. To change the name that is displayed to your contacts, edit your BrandProfile.</p>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-form @submit="editAccountName" onSubmit="return false;" @keyup.enter.native="editAccountName">
                                <v-text-field
                                    v-model="editableAccountName"
                                    ref="accountNameInput"
                                    label="Account name"
                                    :color="primaryColor"
                                    outlined
                                ></v-text-field>
                                <v-card-actions>
                                    <v-row justify="center">
                                    </v-row>
                                </v-card-actions>
                            </v-form>
                            <v-row justify="center">
                                <v-btn elevation="4" :style="primaryButtonStyle" @click="editAccountName" :disabled="!isEditAccountNameFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditAccountName = false">Cancel</v-btn>
                            </v-row>
                        </v-card>
                    </v-dialog> -->
                    <!-- TODO: successor settings, manage admins (or do that in the users view) -->
                    <!-- <v-expansion-panels class="mt-8 mb-6">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span :style="primaryIconStyle"><font-awesome-icon :icon="['fas', 'shield-alt']" class="mr-1"></font-awesome-icon> Security</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p class="mb-0 pb-0">
                                    <router-link :to="{ name: 'tbd', params: { brandprofile: this.$route.params.brandprofile, accountId: this.$route.params.accountId } }">Name a successor</router-link>
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels> -->
                    <!-- TODO: transfer account to new owner -->
                    <v-expansion-panels class="mt-8 mb-6">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span class="red--text text--darken-2"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="mr-1"></font-awesome-icon> Cancel service</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p class="mb-0 pb-0">
                                    <router-link :to="{ name: 'account-service-cancel', params: { brandprofile: this.$route.params.brandprofile, accountId: this.$route.params.accountId }, query: { id: this.$route.query.id } }">How to cancel this service</router-link>
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getDisplayText } from '@/sdk/text';
// import { isValidName, isValidEmail, isValidPhone } from '@/sdk/input';

export default {
    data: () => ({
        accountService: null,
        dialogEditAccountName: false,
        editableAccountName: null,
        submitTimestamp: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
            brandprofile: (state) => state.brandprofile,
            palette: (state) => state.palette,
            brand: (state) => state.brand,
            account: (state) => state.account,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryIconStyle: 'primaryIconStyle',
        }),
        // displayName() {
        //     if (this.user.info && this.user.info.displayName) {
        //         return this.user.info.displayName;
        //     }
        //     return '(unknown)';
        // },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        // balanceDisplay() {
        //     if (Number.isFinite(this.accountBillingSummary?.balance)) {
        //         const display = new Intl.NumberFormat('en-US', {
        //             currency: this.accountBillingSummary.currency,
        //             style: 'currency',
        //         }).format(this.accountBillingSummary.balance);
        //         return display;
        //     }
        //     return '';
        // },
        // creditDisplay() {
        //     if (Number.isFinite(this.accountBillingSummary?.credit)) {
        //         const display = new Intl.NumberFormat('en-US', {
        //             currency: this.accountBillingSummary.currency,
        //             style: 'currency',
        //         }).format(this.accountBillingSummary.credit);
        //         return display;
        //     }
        //     return '';
        // },
        productTypeDisplay() {
            return getDisplayText('product_type', this.accountService?.type);
        },
        // isEditAccountNameFormComplete() {
        //     // TODO; also check that the account name is unique and user isn't linked ao any other account with the same name? OR if it's not unique let the server just append a few random characters to it like (2) or (3) or (291)
        //     return this.editableAccountName && this.editableAccountName !== this.account.name;
        // },
    },
    watch: {
        focus() {
            // reload data when user returns to this tab
            this.loadAccountService();
        },
        // dialogEditAccountName(newValue) {
        //     if (newValue) {
        //         this.editableAccountName = this.account.name;
        //         this.$nextTick(() => {
        //             setTimeout(() => { this.$activateInput('accountNameInput'); }, 1);
        //         });
        //     }
        // },
    },
    methods: {
        serviceTypeDisplay(type) {
            if (type === 'saas') {
                return 'SaaS';
            }
            if (type === 'membership') {
                return 'Membership';
            }
            return 'Service';
        },
        async loadAccountService() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccountService: true });
                const response = await this.$client.site(this.brandprofile).account(this.$route.params.accountId).accountService.get({ id: this.$route.query.id });
                console.log(`loadAccountService: response ${JSON.stringify(response)}`); // "type":"item","item":{"id":"06P99Q44TKRQS968JS5G","version":3,"created_on":1685255106829,"account_id":"06P5SY1NWG9C63KSBRXG","product_id":"06NTS6S5M8GA0PQ8X4MG","price":{"id":"06NTS70K5C6BQBEQYET0","published":false,"version":1,"created_on":1681683630137,"deleted_on":null,"product_id":"06NTS6S5M8GA0PQ8X4MG","alias":"free","currency":"USD","recurring":false,"billing_method":"free","tiers":[],"recurring_interval_unit":null,"recurring_interval_count":null,"recurring_usage_type":null,"metered_usage_method":null,"unit_label":null,"stripe_price_id":null,"info":null,"ref":null,"restricted":null},"is_active":true,"name":"Example Service (Free)","type":"saas","order_id":"06P99Q0CWT7A945JW110","description":null,"alias":"example","ref":"example"}}
                if (response?.type === 'item' && response?.item) {
                    this.accountService = response.item;
                } else {
                    this.accountService = null;
                }
            } catch (err) {
                console.error('loadAccountService: failed to load account service info', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccountService: false });
            }
        },
        // async editAccountName() {
        //     if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
        //         return;
        //     }
        //     this.submitTimestamp = Date.now();
        //     try {
        //         const result = await this.$client.site(this.brandprofile).account(this.account.id).self.edit({ name: this.editableAccountName });
        //         if (result.isEdited) {
        //             this.$emit('snackbar', { type: 'success', headline: 'Saved changes' });
        //             this.$store.dispatch('loadAccount', { accountId: this.account.id });
        //         } else {
        //             this.$emit('snackbar', { type: 'error', headline: 'Failed to save changes' });
        //         }
        //         this.dialogEditAccountName = false;
        //     } catch (err) {
        //         console.error('editAccountName failed', err);
        //         if (err.response?.status === 409) {
        //             this.$emit('snackbar', { type: 'error', headline: 'You already have an account with the same name' });
        //         } else {
        //             this.$emit('snackbar', { type: 'error', headline: 'Failed to save changes' });
        //         }
        //     }
        // },
        async loadAccount(accountId) {
            await this.$store.dispatch('loadAccount', { accountId });
            if (this.account) {
                this.$nav.set('account_id', this.account.id);
            }
        },
    },
    mounted() {
        if (!this.account) {
            this.loadAccount(this.$route.params.accountId);
        }
        this.loadAccountService();
    },
};
</script>
